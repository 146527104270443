<template>
  <!-- Menu -->
  <MenuVue :links="links">
    <span class="prim"></span>
    <span class="prim"><img :src="logo" width="100px" alt="Logo"></span>
  </MenuVue>
  <HeroSection id="hero" pic="header.png">
    <template v-slot:welcome>Olá!</template>
    Me chamo <b>Edrian Biagi.</b>
    Sou bacharel em Ciência da Computação com mais de uma década de sólida experiência
    em desenvolvimento web e mobile. Sou apaixonado por enfrentar desafios
    complexos e estou constantemente buscando oportunidades que me permitam
    expandir meus conhecimentos e habilidades.
    <template v-slot:buttons>
      <simple-button class="btn-primary text-white" url="https://www.linkedin.com/in/edrianbiagi/"
        icon="fab linkedin-in">
        Meu Linkedin
      </simple-button>

    </template>
  </HeroSection>
  <SkillsGrid id="skills" :skills="skills" />
  <ExperienceTable id="edu-exp" :experience="experience" />
  <ProjectsGrid id="projects" :projects="projects" />
  <FooterVue :links="socials" :copyright="copyright" />
</template>

<script>
import MenuVue from "@/components/shared/AppMenu.vue";
import FooterVue from "@/components/shared/AppFooter.vue";
import HeroSection from "@/components/hero/HeroSection.vue";
import SkillsGrid from "@/components/skills/SkillsGrid.vue";
import ExperienceTable from "@/components/experience/ExperienceTable.vue";
import ProjectsGrid from "@/components/projects/ProjectsGrid.vue";

// data to fill components
import skills from "@/data/skills";
import experience from "@/data/experience";
import projects from "@/data/projects";

import SimpleButton from "@/components/reusable/SimpleButton.vue";

// Importar a imagem
import logo from "@/assets/images/edrian.png";

export default {
  name: "HomeView",
  data: () => {
    return {
      delay: 100,
      links: [
        {
          name: "home",
          url: "#hero",
        },
        {
          name: "soluções",
          url: "#skills",
        },
        {
          name: "experiência",
          url: "#edu-exp",
        },
        {
          name: "projetos",
          url: "#projects",
        },
      ],
      skills,
      experience,
      projects,
      socials: [
        {
          id: 1,
          icon: "fab linkedin-in",
          url: "https://www.linkedin.com/in/edrianbiagi/",
        },
        {
          id: 2,
          icon: "fab github",
          url: "https://github.com/edrianbiagi",
        },
      ],
      copyright: "2022 Edrian Biagi",
      // Adicionar a variável da imagem
      logo
    };
  },
  components: {
    MenuVue,
    FooterVue,
    HeroSection,
    SkillsGrid,
    ExperienceTable,
    ProjectsGrid,
    SimpleButton,
  },
};
</script>

<style lang="scss" scoped>
.prim {
  color: $primary;
}
</style>
