const experience = [
  {
    id: 1,
    date: "Jan 2022 - AGO 2024",
    position: "Desenvolvedor Full Tack",
    company: "Trinus.Co",
    url: "https://trinus.co/",
    description: [
      {
        id: 1,
        description:
          "Atuei como desenvolvedor Full Stack na criação de novas features e sustentação do app Trinus, disponível nas plataformas Android e iOS",
      },
      {
        id: 2,
        description:
          "A Trinus surge como uma solução inovadora, oferecendo uma plataforma tecnológica integrada para o mercado imobiliário",
      },
      {
        id: 3,
        description:
          "Backend em C# e frontend em Flutter. Também atuei com Node.js, Scrum, Clean Architecture, Criação e execução de testes",
      },
    ],
  },
  {
    id: 2,
    date: "NOV 2021 - JUN 2022",
    position: "Desenvolvedor Full Stack",
    company: "Rúmicash",
    url: "https://rumina.com.br/rumicash/",
    description: [
      {
        id: 1,
        description:
          "Como profissional Full Stack na RúmiCash, tive o privilégio de atuar com o desenvolvimento e sustentação de uma plataforma inovadora voltada para o setor produtivo de leite",
      },
      {
        id: 2,
        description:
          "No frontend, trabalhei com Flutter web, proporcionando uma interface intuitiva e responsiva. No backend, utilizei Node.js, garantindo um sistema robusto e escalável para lidar com as demandas crescentes da plataforma.",
      },
      {
        id: 3,
        description:
          "Desenvolvi e aprimorei a integração da plataforma RúmiCash com o core bancário, assegurando a efetividade das transações financeiras e proporcionando uma conexão segura e confiável com instituições financeiras parceiras.",
      },
    ],
  },
  {
    id: 3,
    date: "JAN 2019 - JUN 2022",
    position: "Desenvolvedor Full Stack",
    company: "FTecnologia Sistemas",
    url: "https://www.ftecnologiasistemas.com.br/",
    description: [
      {
        id: 1,
        description:
          "Atuei como Full Stack Developer na criação de soluções tecnológicas voltadas para o trânsito",
      },
      {
        id: 2,
        description:
          "Utilizei Flutter para o desenvolvimento de aplicativos móveis e Node.js para o backend. Uma das minhas realizações notáveis foi a integração do Flutter com o Arduino, devido à necessidade de os carros estarem equipados com dispositivos desenvolvidos pela empresa para coleta e transmissão de dados em tempo real",
      },
      {
        id: 3,
        description:
          "Também desempenhei o papel de Tech Lead, liderando a equipe técnica. Nesta posição, fui responsável por coordenar os esforços de desenvolvimento, garantir a qualidade do código, orientar os membros da equipe e assegurar que todos os objetivos do projeto fossem alcançados de maneira eficiente",
      },
    ],
  },
  {
    id: 4,
    date: "JAN 2016 - AGO 2016",
    position: "Monitor de Sistemas",
    company: "Techlead IT Solutions",
    url: "https://www.techlead.com.br/",
    description: [
      {
        id: 1,
        description:
          "Supervisionava continuamente os sistemas para identificar falhas ou anomalias que pudessem comprometer a operação",
      },
      {
        id: 2,
        description:
          "Quando possível, implementava soluções imediatas para resolver os problemas identificados, garantindo a mínima interrupção dos serviços",
      },
      {
        id: 3,
        description:
          "Nos casos em que os problemas exigiam uma intervenção mais complexa, acionava prontamente a equipe de sobreaviso, fornecendo um relatório detalhado da situação para uma resolução rápida e eficaz",
      },
      {
        id: 4,
        description:
          "Registrava todos os incidentes e as ações tomadas, contribuindo para a base de conhecimento e ajudando a prevenir problemas futuros",
      },
    ],
  },
];

export default experience;
