<template>
  <metainfo> </metainfo>

  <transition name="fade" mode="out-in">
    <router-view />
  </transition>

  <!-- Scroll to top -->
  <back-to-top visibleoffset="500" right="30px" bottom="20px" class="shadow-lg">
    <i data-feather="chevron-up"></i>
  </back-to-top>
</template>
<script>
// aos
// import AOS from "aos";
// import "aos/dist/aos.css";
import { useMeta } from "vue-meta";

export default {
  data: () => {
    return {
      webTitle: "Edrian Biagi - Backend Developer",
    };
  },
  setup() {
    useMeta({
      title: "Edrian Biagi - Desenvolvedor Full Stack",
      description: "Just a guy with passion for coding",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      twitter: {
        title: "Edrian Biagi - Backend Developer",
        description: "Just a guy with passion for coding",
        card: "summary_large_image",
        image: "https://rusetskii.dev" + require(`@/assets/images/preview.jpg`),
      },
      og: {
        title: "Edrian Biagi - Backend Developer",
        description: "Just a guy with passion for coding",
        type: "website",
        url: "https://rusetskii.dev/",
        image: "https://rusetskii.dev" + require(`@/assets/images/preview.jpg`),
        site_name: "Edrian Biagi - Backend Developer",
      },
      meta: [
        {
          name: "keywords",
          content:
            " desenvolvedor full stack, portfólio, desenvolvimento web, frontend, backend, JavaScript, Node.js, React, Vue.js, Laravel, Flutter, soluções web, tecnologia, aplicações web, desenvolvedor de software, edrian desenvolvedor",
        },
        {
          name: "author",
          content: "Edrian Biagi",
        },
      ],
      link: [
        {
          rel: "icon",
        },
      ],
    });
  },
  // mounted() {
  //   AOS.init();
  // },
};
</script>
<style lang="scss">
html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: $default-family;
  font-size: $default-font-size;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-thumb {
  background: #363636;
}

::-webkit-scrollbar-track {
  background: #fff;
}

.btn {
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.5em - 1px) 1em;
  text-align: center;
  white-space: nowrap;
  -webkit-appearance: none;
  align-items: center;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
}

.btn-primary {
  color: white;
}

a,
a:active,
a:visited {
  text-decoration: none !important;
}
</style>
