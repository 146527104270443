const skills = [
  {
    id: 1,
    title: "Back End",
    image: "backend_blue.png",

    description:
      "Desenvolvo APIs robustas e escaláveis para conectar e otimizar sistemas, garantindo segurança e eficiência no processamento de dados",
  },
  {
    id: 2,
    title: "Front End",
    image: "frontend_blue.png",

    description:
      "Crio interfaces interativas e intuitivas que proporcionam uma experiência de usuário fluida e acessível em todas as plataformas.",
  },
  {
    id: 3,
    title: "Aplicativos",
    image: "ai_blue.png",

    description:
      "Desenvolvo aplicativos móveis personalizados, focados em desempenho e usabilidade, para atender às necessidades específicas dos usuários.",
  },
];

export default skills;
